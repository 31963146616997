$(document).on("turbo:load", function () {
  $("#character_upgrade_capabilities").on("click", function (e) {
    $("#character_upgrade_capabilities_choices").toggle();
    handle_state_change();
  });

  $("#character_upgrade_perfection").on("click", function (e) {
    $("#character_upgrade_perfection_choices").toggle();
    handle_state_change();
  });

  $("#character_upgrade_effort").on("click", function (e) {
    $("#character_upgrade_effort_choices").toggle();
    handle_state_change();
  });

  $("#character_upgrade_skill").on("click", function (e) {
    $("#character_upgrade_skill_choices").toggle();
    handle_state_change();
  });

  $("#character_upgrade_other").on("click", function (e) {
    $('#character_upgrade_other_choice').val('recovery_roll');
    $("#character_upgrade_other_choices").toggle();
    handle_state_change();
    display_skill_selection('recovery_roll');
  });

  $("#might_pool_up").on("click", function (e) {
    modify_displayed_value("#character_might_pool", 1);
  });

  $("#might_pool_down").on("click", function (e) {
    modify_displayed_value("#character_might_pool", -1);
  });

  $("#speed_pool_up").on("click", function (e) {
    modify_displayed_value("#character_speed_pool", 1);
  });

  $("#speed_pool_down").on("click", function (e) {
    modify_displayed_value("#character_speed_pool", -1);
  });

  $("#intellect_pool_up").on("click", function (e) {
    modify_displayed_value("#character_intellect_pool", 1);
  });

  $("#intellect_pool_down").on("click", function (e) {
    modify_displayed_value("#character_intellect_pool", -1);
  });

  $("#might_edge_up").on("click", function (e) {
    modify_displayed_edge_value("#character_might_edge", 1);
  });

  $("#might_edge_down").on("click", function (e) {
    modify_displayed_edge_value("#character_might_edge", -1);
  });

  $("#speed_edge_up").on("click", function (e) {
    modify_displayed_edge_value("#character_speed_edge", 1);
  });

  $("#speed_edge_down").on("click", function (e) {
    modify_displayed_edge_value("#character_speed_edge", -1);
  });

  $("#intellect_edge_up").on("click", function (e) {
    modify_displayed_edge_value("#character_intellect_edge", 1);
  });

  $("#intellect_edge_down").on("click", function (e) {
    modify_displayed_edge_value("#character_intellect_edge", -1);
  });

  $("#character_upgrade_other_choice").on("change", function (e) {
    display_skill_selection(e.target.value);
  });

  $("#character_upgrade_other_ability_choice").on("change", function (e) {
    display_help("/help/ability", e.target.value.split("__")[0]);
  });

  function display_skill_selection(skill_name) {
    $("#character_upgrade_other_armor_choice").hide();
    $("#character_upgrade_other_recovery_roll_choice").hide();
    $("#character_upgrade_other_ability_choice").hide();
    $(`#character_upgrade_other_${skill_name}_choice`).show();

    $("#character_upgrade_other_other_choice").toggle(skill_name == "other");
    $("#character_upgrade_other_armor_choice").toggle(skill_name == "armor");
    $("#character_upgrade_other_recovery_roll_choice").toggle(skill_name == "recovery_roll");

    if (skill_name == "ability") {
      display_help("/help/ability",
        $("#select_character_upgrade_other_ability_choice").val().split("__")[0]
      );
    }
  }

  function handle_state_change() {
    update_xp_cost();
    display_upgrade_abilities();
  }

  function update_xp_cost() {
    cost = 0;
    if (
      $("#character_upgrade_capabilities").is(":checked") &&
      !$("#character_upgrade_capabilities").is(":disabled")
    ) {
      cost += 4;
    }
    if (
      $("#character_upgrade_perfection").is(":checked") &&
      !$("#character_upgrade_perfection").is(":disabled")
    ) {
      cost += 4;
    }
    if (
      $("#character_upgrade_effort").is(":checked") &&
      !$("#character_upgrade_effort").is(":disabled")
    ) {
      cost += 4;
    }
    if (
      $("#character_upgrade_skill").is(":checked") &&
      !$("#character_upgrade_skill").is(":disabled")
    ) {
      cost += 4;
    }
    if (
      $("#character_upgrade_other").is(":checked") &&
      !$("#character_upgrade_other").is(":disabled")
    ) {
      cost += 4;
    }

    $("#xp_cost_to_advance_display").text(cost);
    $("#xp_cost_to_advance").val(cost);
  }

  function display_upgrade_abilities() {
    const checkboxes = [
      "#character_upgrade_capabilities",
      "#character_upgrade_perfection",
      "#character_upgrade_effort",
      "#character_upgrade_skill",
      "#character_upgrade_other"
    ];

    let countCheckedOrMissing = 0;

    // For each checkbox selector, see if it exists or is checked.
    // If it's missing OR checked, increment our counter.
    for (const selector of checkboxes) {
      const $checkbox = $(selector);
      if ($checkbox.length === 0 || $checkbox.is(":checked")) {
        countCheckedOrMissing++;
      }
    }

    // Now check if at least 4 meet the "checked or missing" condition
    if (countCheckedOrMissing >= 4) {
      $("#character_upgrade_ability_choices").show();
    } else {
      $("#character_upgrade_ability_choices").hide();
    }
  }

  function modify_displayed_value(div_id, int_value) {
    available = parseInt($("#pool_to_spend").text());

    if (isNaN(available)) {
      return;
    }

    if (available < 1 && int_value == 1) {
      return;
    }

    if (available >= starting_total_pool_points && int_value == -1) {
      return;
    }

    /* check the val > current pool and don't decrement */

    current = $(div_id).text();
    val = parseInt(current);
    val += int_value;

    if (div_id == "#character_might_pool") {
      if (val < starting_might_pool) {
        return;
      }
      $("#might_pool_increase").val(val);
    }

    if (div_id == "#character_speed_pool") {
      if (val < starting_speed_pool) {
        return;
      }
      $("#speed_pool_increase").val(val);
    }

    if (div_id == "#character_intellect_pool") {
      if (val < starting_intellect_pool) {
        return;
      }
      $("#intellect_pool_increase").val(val);
    }

    $(div_id).text(val);
    $("#pool_to_spend").text(available - int_value);
  }

  function modify_displayed_edge_value(div_id, int_value) {
    available = parseInt($("#edge_to_spend").text());

    if (isNaN(available)) {
      return;
    }

    if (available < 1 && int_value == 1) {
      return;
    }

    if (available >= starting_total_edge_points && int_value == -1) {
      return;
    }

    /* check the val > current pool and don't decrement */

    current = $(div_id).text();
    val = parseInt(current);
    val += int_value;

    // tier 1 characters can't have an edge > 2 ?
    // warrior lets them choose
    // golem body gives you +1 might edge, so you can get at least 2
    // improved edge requires them to select one that's currently zero, but we don't have a way to really enforce that?
    if (val > 2) {
      return;
    }

    if (div_id == "#character_might_edge") {
      if (val < starting_might_edge) {
        return;
      }
      $("#might_edge_increase").val(val);
    }

    if (div_id == "#character_speed_edge") {
      if (val < starting_speed_edge) {
        return;
      }
      $("#speed_edge_increase").val(val);
    }

    if (div_id == "#character_intellect_edge") {
      if (val < starting_intellect_edge) {
        return;
      }
      $("#intellect_edge_increase").val(val);
    }

    $(div_id).text(val);
    $("#edge_to_spend").text(available - int_value);
  }

  function display_help(get_url, value) {
    $.ajax({
      type: "GET",
      url: get_url,
      data: { value: value },
      dataType: "json",
      success: function (data) {
        result = data.help;
        if (result == "") {
          title = "Help";
        }

        $("#context_help").text(result);
      },
    });
  }

  // Setup initial state
  handle_state_change();
});
